// Use this to write your custom SCSS
.navbar-nav .dropdown .nav-link.active {
    padding: var(--bs-dropdown-item-padding-y) var(--bs-dropdown-item-padding-x);
    opacity: 0.6 !important;
    font-size: 15px;
}

.navbar-nav .nav-link.active, .navbar-nav .nav-link.show {
    /** color: var(--bs-navbar-active-color); **/
    opacity: 0.6 !important;
  }

.rojo-it {
    color: #fb1212;
}

.nav-tabs .nav-link {
  color: #999;
  // opacity: .6 !important
}
/*
.list-unstyled li a,
.list-unstyled li a.active {
    color: var(--bs-primary) !important;
    font-weight: normal !important;
  }
*/

.bg-light-alpha-90::before, .bg-light-alpha-90 {
  background: rgba(252,252,252, .9);
}

.link:hover{
  cursor: pointer;
}

.list-unstyled > li > a.nav-link {
  --bs-nav-link-font-weight: 400;
  color: inherit;
}

p.alert-mensual-planes {
  text-align: left;
  margin-bottom: 0;
  line-height: 1.5 !important;
}

.table-cell{
  display: table-cell !important;
}

.card-planes{
  background: #fefefe;
  text-align: center;
  box-shadow: none;
}

a.float-wa{
	position:fixed;
	width:60px;
	height:60px;
	bottom:40px;
	right:40px;
	background-color:#25d366;
	color:#FFF;
	border-radius:50px;
	text-align:center;
  font-size:30px;
  z-index:10000;
}

i.my-float-wa{
	margin-top:16px;
  font-size: 1.8rem !important;
}

